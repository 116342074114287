export const QS_FAQ_DATA_WHAT = [
    [
        'What is QuickSense?',
        'QuickSense is a learning program that will guarantee that you will get an A* - A. We use proven methods from psychology and neuroscience to make learning maths much easier and more efficient.'
    ],
    [
        'How does it work?',
        'You log in and answer questions (only 15 minutes a day). Every single question has step by step explanations so you can learn where you made the mistake and how to solve it. Our learning algorithm learns your strengths and weaknesses and gives you questions until you master them.'
    ],
    [
        'Does it have videos?',
        'Yes, we have videos. But you don\'t need to watch these unless you get stuck.'
    ],
    [
        'How long does the program take to finish?',
        'We recommend using it from the start of the year and doing 15 minutes a day, 3-4 days a week to make it much easier. But the whole program can be completed in 4 weeks if you commit to it  (~3 hours every day). '
    ],
    [
        'Does this mean I don\'t have to study?',
        'No, you\'ll have to put in the work and study. QuickSense just makes it easier, more efficient and much more effective. ',
        'You\'ll study smarter not harder.'
    ],
    [
        'Do I need anything else?',
        'QuickSense is designed to be used standalone — the only thing you\'ll need to get an A*- A.',
        'Although we do have exam papers on the platform, we strongly encourage students to do past paper exams under exam conditions to practice exam technique and timing.',
        'We will be releasing a guide to our students on how to best use past papers closer to the exams. '
    ],
    [
        'What subjects do you cover?',
        'Currently, we only cover IGCSE Maths.',
        'We will be expanding out to AS and A-level maths, then into the sciences.'
    ],
    [
        'What exam board is this for?',
        'We specifically cover the CAIE and Edexcel exam boards — both have exactly the same content for maths. '
    ],
    [
        'Can this be used for GCSEs?',
        'Yes, for maths, the content and syllabuses for IGCSE and the GCSE exam boards are the same.',
        'The only difference is the structure and style of the final exams.',
        'Our A*- A (or 9 - 7 grade) guarantee will also apply to GCSEs.'
    ]
]

export const QS_FAQ_DATA_WHO = [
    [
        'I\'ve only got less than <4 weeks left before the exam, is this still useful?',
        'You can complete the full course in 4 weeks if you spend around 3 hours every day on it (including weekends). You could do it even faster if you committed more time. '
    ],
    [
        'I\'m already on track to get an A, should I use this?',
        'If you are already on track to an A, QuickSense will make your maths study much much more efficient and free up precious study time to focus on other subjects.',
        'Because you don\'t have to watch videos (only answer questions) you can fly through the things you already know and focus on your weak spots.'
    ],
    [
        'I\'m getting D\'s and C\'s, is this for me? ',
        'Yes definitely! The content starts from fundamentals and has step by step explanations and videos you can watch to help you understand the concept and solve them for the exam.',
        'If you put in the work, we will take you from D\'s and C\'s to an A* - A — guaranteed. '
    ],
    [
        'What if I don\'t want an A - A*, should I use this?',
        'Although it is designed for students who want to get the best possible grade, you can use QuickSense to help you pass. Just aim for 2 stars across all the lessons instead of 3 stars.',
        'You will get there much faster than if you studied by yourself. '
    ],
    [
        'I already have a tutor, should I get this?',
        'Yes, a lot of our students use QuickSense to make tutoring more effective by working through concepts and problems identified as weaknesses on the QuickSense app. '
    ],
    [
        'What if this doesn\'t suit my learning style? ',
        'Learning styles are a <a href=\'https://www.researchgate.net/profile/Cedar-Riener-2/publication/249039450_The_Myth_of_Learning_Styles/links/0046353c694205e957000000/The-Myth-of-Learning-Styles.pdf\' rel=\'noreferrer\' target=\'_blank\'>common myth</a> — it has no neuroscientific basis. ',
        'QuickSense is built principles on how people actually learn — see our How it Works page'
    ]
]

export const QS_FAQ_DATA_A_GRADE = [
    [
        'Is the A*- A guarantee real? What\'s the catch?',
        '100% real, no catch. We\'ve tested and perfected our program and are 100% sure it works for everyone.',
        'All you have to do is complete the course and you\'ll get an A*- A — guaranteed. '
    ],
    [
        'Is this one of those things that will take thousands of hours to study to be eligible?',
        'Not that all. Because we only offer the guarantee based on questioned answered (videos are optional). We estimate that it takes around 90 hours of work to complete the course for the average student. Most students complete it in less time.',
        'If you are struggling, you will naturally need to put in more work (watch videos, go over questions more slowly, read explanations in detail).'
    ],
    [
        'Why do you do this?',
        'Because we really know this works. We wanted to make getting A* - A in IGCSE fool proof and stress free.'
    ],
    [
        'How do I get the refund?',
        'If you complete the program and don\'t get an A*- A as your final grade, just send us an email with a copy of your transcript — we\'ll refund you 100% of what you paid within 48 hours. ',
        'See our A*- A Guarantee page for more details.'
    ]
]

export const QS_FAQ_DATA_PRICING = [
    [
        'How much does it cost?',
        'The program costs a total of £97 which works out of to be approximately £4 per month (over two years). This is equal to the cost of 2-3 hours of high-quality private tutoring. '
    ],
    [
        'How long do I have access for?',
        'The maths curriculum is most often taught over two academic years so we offer access to QuickSense for 24 months. '
    ],
    [
        'Can I share access with my friends?',
        'You could, but you will not be eligible for the A* - A guarantee and the learning algorithm will not be personalised to you.   '
    ],
    [
        'Can we purchase multiple licences for a discount?',
        'Yes - we will be releasing our referral program short which will have more details. In the meantime, contact us if you want to buy multiple licences (a group of friends buying) and we will manually give you each an offer code. '
    ],
    [
        'Is it a one-time payment or subscription?',
        'Our pricing is a one-time payment of £97. ',
        'We do this to encourage people to use QuickSense as early as possible. '
    ]
]

export const QS_FAQ_DATA_PARENTS = [
    [
        'Will I be able to monitor my child progress?',
        'Not currently, but we are releasing this feature very soon! '
    ],
    [
        'My child has already signed up for an account — what do I do?',
        'You can upgrade to the paid version directly from your child\'s account. You can email us at hello@quicksense.org from you email and your child\'s name and we will send you email updates on your child\'s progress'
    ],
    [
        'I\'ve signed up on behalf of my child — what do I do? ',
        'Once you\'ve upgraded to a paid account you can send the log-in details to your child to use the app'
    ]
]

export const QS_FAQ_DATA_TUTORS = [
    [
        'Are you trying to replace tutors?',
        'Not at all! We want to work with tutors by giving you the data on where each student\'s weaknesses lie to make tutoring sessions even more effective. ',
        'We will be releasing specific dashboards for tutors in the future. '
    ],
    [
        'How will QuickSense help me and my students?',
        'Tutors and students can use QuickSense as a tool to identify strengths and weaknesses in a student\'s knowledge. You can use this data to focus tutoring sessions to work through areas where the student needs the most help.'
    ],
    [
        'Can my students get a discount?',
        'Yes! If you\'re a tutor all of your students can get a discount when they sign-up through you. Just email us <link us email> and we\'ll sort it out. '
    ],
    [
        'Can I get an affiliate fee if I sign my students up ',
        'Yes! We will pay you an affiliate fee for every paying student that signs up directly through you. Email us at hello@quicksense.org for details.'
    ],
    [
        'How do I sign my students up for QuickSense?',
        'Send us an email with your tutoring company name (or your name if you\'re an individual) and the number of students you want to sign-up with. We will arrange for a code that can be used by your students. '
    ]
]

export const QS_FAQ_DATA_SCHOOLS = [
    [
        'Can QuickSense be used in the classroom?',
        'Yes! We can set you up with a school account where you can bulk upload your classroom(s) and be able to track student participation and progress.'
    ],
    [
        'As a teacher, can I sign-up my classroom?',
        'Yes! We can sign-up individual classrooms for teachers.'
    ],
    [
        'What is the pricing for schools and teachers?',
        'We have special pricing which is significantly lower for schools and teachers based on the number of students signed up — contact us. '
    ]
]
