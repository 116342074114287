import React from 'react'

// components
import Col from 'react-bootstrap/Col'
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from  '../components/GetStarted/getStarted'

//utils
import { QS_FAQ_DATA_WHAT, QS_FAQ_DATA_WHO, QS_FAQ_DATA_A_GRADE, QS_FAQ_DATA_PRICING, QS_FAQ_DATA_TUTORS, QS_FAQ_DATA_PARENTS,  QS_FAQ_DATA_SCHOOLS } from '../utils/pages/qsFAQPageData'

class FAQPage extends React.Component {

    render(){
        return <>
            <SEO title='FAQ'/>
            <Layout classes='fw-landing-page'>
                {
                    QS_FAQ_DATA_WHAT.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'What is QuickSense?' : ''}
                            spacing= {index==0 ? 'pt-4 mt-3' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<5 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_WHO.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'Who is this for?' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-green'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_A_GRADE.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'A* Guarantee' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-purple'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_PRICING.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'PRICING' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-yellow'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_PARENTS.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'PARENTS' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-pink'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_TUTORS.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'TUTORS' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-green'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }
                {
                    QS_FAQ_DATA_SCHOOLS.map((section, index) => (
                        <Section
                            superTitle={index==0 ? 'SCHOOLS' : ''}
                            spacing= {index==0 ? 'pt-4' : 'pt-1 pb-2'}
                            animation={false}
                            key={index} 
                            background={'bg-light-yellow'}
                            center={true}
                            title={`${index+1}. ${section[0]}`}
                            titleClasses={'h3'}
                        >
                            <Col lg={8}>
                                {
                                    section.map((content, index) =>(
                                        index>0 &&
                                            <p className='text-primary-high' key={index} dangerouslySetInnerHTML={{__html: content}}/>
                                    ))
                                }
                                { 
                                    index<11 && 
                                        <hr className='w-100 pb-2' />
                                }
                            </Col>
                        </Section>
                    ))
                }

                <GetStarted
                    background='bg-light-blue'
                    buttonVariant='primary'
                    secondaryButton={true}
                    title={`All you need to do is answer questions `}
                    description={`This might be a lot to take in, but if you use QuickSense you don't need to worry about it. Our algorithm has this built-in and does it all for you. <br/><br/> All you need to do is follow our program and answer the questions.`}
                    secondaryButtonText={`Just answer questions and you will get an A - A* - guaranteed`}
                />
            </Layout>
        </>
    }
}

export default FAQPage